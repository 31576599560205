import React from "react";
import { ContactForm } from "@/components/contact-form";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";
import { ApplicationForm } from "@/components/application-form";

const FlutterDeveloperPage = () => {
  return (
    <>
      <GatsbySeo
        title="Flutter Developer - Careers | Tectra Technologies"
        canonical="https://www.tectratechnologies.com/careers/flutter-developer/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/careers/flutter-developer/",
          title: "Flutter Developer - Careers | Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white mt-20 lg:mt-24">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-3xl font-poppins font-normal captalize tracking-tight">
              Flutter Developer
            </h2>

            <p className="text-lg font-poppins font-thin text-black">
              Chennai, IN - Full Time
            </p>

            <div className="mt-6">
              <ApplicationForm job_type="Flutter Developer" />
            </div>
          </div>
        </div>

        <div className="bg-white mt-4">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <div className="grid lg:grid-cols-12">
              <div className="lg:col-span-12">
                <h5 className="mb-4 mt-2 font-medium underline text-lg">
                  Requirements:-
                </h5>

                <ul className="list-disc text-black ml-8">
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Strong knowledge of Android SDK, XCode & Flutter Framework
                    different versions of Android, IOS and how to deal with
                    different screen sizes.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Experience with the Android SDK, Java, Kotlin, Dart & Xcode.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Experience with JSON concepts and REST APIs to connect
                    mobile applications to back-end services
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Strong knowledge of Mobile UI design principles, patterns,
                    and best practices
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Experience with offline storage, threading, and performance
                    tuning
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Knowledge of the open-source Android ecosystem and the
                    libraries available for common tasks
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Familiarity with cloud message APIs and push notifications
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Proficient understanding of code versioning tools, such as
                    Git
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Familiarity with continuous integration and Knowledge of
                    emerging technologies, mobile architectures,
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Understanding of Google’s Android design principles and
                    interface guidelines Secure in-app purchases
                  </li>
                </ul>

                <h5 className="mb-4 mt-6 font-medium underline text-lg">
                  Job Responsibilities:-
                </h5>

                <ul className="list-disc mb-0 ml-8">
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Design and build advanced applications for the Flutter
                    platform.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Experience with bloc, provider.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Must have built at least two Android/iOS apps with Flutter,
                    preferably deployed on stores.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Ability to write readable code and refactor the previously
                    written code into a readable state
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Experience with third-party libraries and APIs.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Understanding of the Agile development life-cycle.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default FlutterDeveloperPage;
